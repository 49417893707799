/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { DataPublisherService } from 'core/api-services';
import { EMPTY_GUID, LOCALSTORAGE } from 'core/constants';

import {
  AdxVehicleMessageRecord,
  AllowedNumberOfZoneSetsDto,
  CollisionPoint,
  EdgeDto,
  IntersectionCollisionPoint,
  LayoutDto,
  MapDto,
  MapVehicle,
  NavigationLayerResponseModel,
  NavigationLayerStorageModel,
  NodeDto,
  NodeGroupDto,
  OpcuaDeviceResponseModel,
  PillarsGridDto,
  PoiDto,
  RouteConfigurationDto,
  RuleDto,
  VehicleAwarenessDto,
  VehicleZoneUpdate,
  ZoneSetDto,
} from 'core/dtos';
import { filterUndefined } from 'core/helpers';
import {
  BatteryLevelStatus,
  GraphLayout,
  GuidString,
  LoadType,
  LoadTypeConfigurationDto,
  MapMode,
  Mission,
  MissionTrace,
  SignalRNextMessage,
  SoftwareUpdateStatus,
  VehicleAvailability,
  VehicleInterfaceType,
  VehicleStatus,
  WorkingArea,
  ZoneSetStatus,
} from 'core/models';
import { StorageService } from 'core/services/storage.service';
import {
  MapVehicleSignalRService,
  MissionTraceSignalRService,
  VehicleSignalRService,
  VehicleSignalrSubscriber,
} from 'core/signalR/modules';
import { SignalRNextService } from 'core/signalR/signalr-next.service';
import { isEqual } from 'lodash';
import { MissionListInputModel } from 'modules/jobs/mission-monitoring/components/mission-list/mission-list-model';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { MapContainerDetails } from '../models';
import { SelectedMapData } from '../models/map-data';
import { MapCommunicationService } from './map-communication.service';
import { mapAdxRecordToCollision, setVehicleDefaultValues } from './map-data.helper';

import * as fromGraphManager from 'store-modules/graph-manager';
import * as fromMaps from 'store-modules/maps-store';
import * as fromMissionMonitoring from 'store-modules/mission-monitoring-store';
import * as fromOpcuaDevices from 'store-modules/opcua-devices-store';
import * as fromPois from 'store-modules/pois-store';
import * as fromProcessConfigurator from 'store-modules/process-configurator-store';
import * as fromSettings from 'store-modules/settings-store';
import * as fromVehicles from 'store-modules/vehicles-store';
import * as fromRoot from 'store/index';

@Injectable({
  providedIn: 'root',
})
export class MapDataService {
  // #region Members
  private currentMap: SelectedMapData | undefined;

  mode$!: Observable<MapMode | undefined>;
  mapId$!: Observable<GuidString | undefined>;
  selectedMap$!: Observable<MapDto>;
  selectedMapEmpty$!: Observable<boolean | undefined>;
  activeZoneSet$!: Observable<ZoneSetDto | undefined>;
  isZoneSetLimitReached$!: Observable<boolean | undefined>;
  currentZoneSet$!: Observable<ZoneSetDto | undefined>;

  allNavigationLayers$: Observable<NavigationLayerResponseModel[]> = of([]);
  allZoneSets$!: Observable<ZoneSetDto[] | undefined>;
  selectedZoneSet$!: Observable<ZoneSetDto | undefined>;
  isRouteConfigurationMode$!: Observable<boolean | undefined>;

  allPois$!: Observable<PoiDto[]>;
  activeLoadTypeConfigurations$!: Observable<LoadTypeConfigurationDto[]>;
  activeLoadTypes$!: Observable<LoadType[]>;
  pillarsGrid$!: Observable<PillarsGridDto | undefined | null>;
  allMissionsForDispatch$: Observable<Mission[] | undefined> = of([]);
  activeMissionTracesList$: Observable<MissionListInputModel[]> = of([]);
  activeMissionTraces$: Observable<MissionTrace[]> = of([]);
  selectedVehicleMission$: Observable<MissionTrace | undefined> = of();
  allDevices$: Observable<OpcuaDeviceResponseModel[]> = of([]);

  graphLayerEnabled$: Observable<boolean> = of(false);
  graphLayer$!: Observable<LayoutDto | undefined>;
  graphNodes$!: Observable<NodeDto[]>;
  graphEdges$!: Observable<EdgeDto[]>;
  graphRouteRules$!: Observable<RuleDto[]>;
  graphRouteConfigs$!: Observable<RouteConfigurationDto[] | undefined>;
  nodeGroups$: Observable<NodeGroupDto[]> = of([]);

  layerUpdatePoiStatus$!: Observable<PoiDto>;
  layerUpdatePoi$!: Observable<PoiDto>;
  layerCreatedPoi$!: Observable<PoiDto>;
  layerDeletedPoi$!: Observable<GuidString>;

  private readonly graphLayout = new BehaviorSubject<GraphLayout | undefined>(undefined);
  graphLayout$: Observable<GraphLayout | undefined> = this.graphLayout.asObservable();

  // Emitted on map changed - Set ViewPort
  private readonly mapChanged = new BehaviorSubject<SelectedMapData | undefined>(undefined);
  mapChanged$: Observable<SelectedMapData | undefined> = this.mapChanged.asObservable();

  private readonly allVehicles = new BehaviorSubject<MapVehicle[]>([]);
  allVehicles$: Observable<MapVehicle[]> = this.allVehicles.asObservable();

  private readonly allCollisionPoints = new BehaviorSubject<CollisionPoint[]>([]);
  allCollisionPoints$: Observable<CollisionPoint[]> = this.allCollisionPoints.asObservable();

  private readonly allIntersectionCollisionPoints = new BehaviorSubject<
    IntersectionCollisionPoint[]
  >([]);
  allIntersectionCollisionPoints$: Observable<IntersectionCollisionPoint[]> =
    this.allIntersectionCollisionPoints.asObservable();

  get vehicleZoneUpdate$(): Observable<VehicleZoneUpdate[]> {
    return this.mapVehicleSignalRService.vehicleZoneUpdate.asObservable();
  }

  private ngUnsubscribeHistoryData = new Subject<void>();
  ngUnsubscribeLiveData = new Subject<void>();

  private readonly vehicleSignalrSubscriber: VehicleSignalrSubscriber;
  zoneSet: ZoneSetDto | undefined;

  //#endregion

  constructor(
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly storageService: StorageService,
    private readonly mapVehicleSignalRService: MapVehicleSignalRService,
    private readonly missionTraceSignalRService: MissionTraceSignalRService,
    private readonly vehicleSignalRService: VehicleSignalRService,
    private readonly processChainStore: Store<fromProcessConfigurator.ProcessConfiguratorFeatureState>,
    private readonly missionMonitoringStore: Store<fromMissionMonitoring.MonitoringFeatureState>,
    private readonly mapCommunicationService: MapCommunicationService,
    private readonly dataPublisherService: DataPublisherService,
    private readonly signalRNextService: SignalRNextService,
    private readonly settingsStore: Store<fromSettings.SettingsFeatureState>,
    private readonly poisStore: Store<fromPois.PoisFeatureState>,
    private readonly actions$: ActionsSubject
  ) {
    this.vehicleSignalrSubscriber = this.vehicleSignalRService.signalrSubscriberFactory(
      MapDataService.name
    );

    this.subscribeToMap();

    this.subscribeToZoneSetLimitReached();
    this.getCurrentZoneSet();

    this.subscribeToMapLayers();
    this.subscribeToMissions();
    this.subscribeToGraphLayout();
  }

  unsubscribe(): void {
    this.unsubscribeLiveData();
  }

  // #region Initialization
  private subscribeToMap() {
    this.mode$ = this.rootStore.pipe(select(fromMaps.selectMapMode));

    this.selectedMap$ = this.rootStore.pipe(select(fromMaps.selectSelectedMap), filterUndefined());
    this.mapId$ = this.rootStore.pipe(select(fromMaps.selectMapId));
    this.selectedMapEmpty$ = this.createSelectedMapEmpty();
    this.activeZoneSet$ = this.rootStore.pipe(select(fromMaps.selectSelectedMapActiveZoneSet));
    this.allNavigationLayers$ = this.rootStore.pipe(
      select(fromMaps.selectNavigationLayersBySelectedMapId)
    );
  }

  private subscribeToMapLayers() {
    this.allPois$ = this.rootStore.pipe(select(fromPois.selectPoisBySelectedMapId));
    this.activeLoadTypes$ = this.settingsStore.pipe(select(fromSettings.selectActiveLoadTypes));
    this.activeLoadTypeConfigurations$ = this.settingsStore.pipe(
      select(fromSettings.selectActiveLoadTypeConfigurations)
    );
    this.pillarsGrid$ = this.rootStore.pipe(select(fromMaps.selectPillarsGridBySelectedMapId));
    this.allZoneSets$ = this.rootStore.pipe(select(fromMaps.selectAllZoneSets));
    this.selectedZoneSet$ = this.rootStore.pipe(select(fromMaps.selectZoneSetBySelectedZoneSetId));
    this.isRouteConfigurationMode$ = this.rootStore.pipe(select(fromMaps.isRouteConfigurationMode));

    this.allDevices$ = this.rootStore.pipe(select(fromOpcuaDevices.selectAllOpcuaDevices));

    this.layerUpdatePoiStatus$ = this.selectPoiStatusChanged();
    this.layerUpdatePoi$ = this.selectPoiChanged();
    this.layerCreatedPoi$ = this.selectPoiCreated();
    this.layerDeletedPoi$ = this.selectPoiDeleted();
  }

  private subscribeToGraphLayout() {
    this.graphLayer$ = this.rootStore.pipe(select(fromMaps.selectGraphLayersBySelectedMapId));
    this.graphNodes$ = this.rootStore.pipe(select(fromMaps.selectGraphNodesBySelectedMapId));
    this.graphEdges$ = this.rootStore.pipe(select(fromMaps.selectGraphEdgesBySelectedMapId));
    this.graphRouteRules$ = this.rootStore.pipe(
      select(fromGraphManager.selectAllRouteCustomizationRules)
    );
    this.graphRouteConfigs$ = this.rootStore.pipe(
      select(fromGraphManager.selectAllRouteConfigurations)
    );
    this.nodeGroups$ = this.rootStore.pipe(select(fromGraphManager.selectAllNodeGroups));

    this.graphLayerEnabled$ = this.rootStore.pipe(
      select(fromSettings.selectGraphManagerFeatureSettings),
      map(featureSettings => featureSettings.settings.enableGraphManager ?? false)
    );
  }

  private subscribeToMissions() {
    this.allMissionsForDispatch$ = this.processChainStore.pipe(
      select(fromProcessConfigurator.selectAllMissionsForDirectDispatch)
    );
    this.activeMissionTracesList$ = this.missionMonitoringStore.pipe(
      select(fromMissionMonitoring.selectAllMissionActiveList2)
    );
    this.activeMissionTraces$ = this.rootStore.pipe(
      select(fromMissionMonitoring.selectActiveMissions2)
    );

    this.selectedVehicleMission$ = this.missionMonitoringStore.pipe(
      select(fromMissionMonitoring.selectMissionByVehicle)
    );
  }

  private getMapEmpty(
    isMapEmpty: boolean | undefined,
    isNavigationLayersEmpty: boolean | undefined
  ): boolean | undefined {
    if (isMapEmpty === undefined) {
      return undefined;
    }

    if (isMapEmpty) {
      return true;
    }

    if (isNavigationLayersEmpty === undefined) {
      return undefined;
    }

    return isNavigationLayersEmpty;
  }

  private createSelectedMapEmpty(): Observable<boolean | undefined> {
    return combineLatest([
      this.rootStore.pipe(select(fromMaps.selectSelectedMapEmpty)),
      this.rootStore.pipe(select(fromMaps.selectNoNavigationLayersBySelectedMapId)),
    ]).pipe(
      map(([isMapEmpty, isNavigationLayersEmpty]) => {
        return this.getMapEmpty(isMapEmpty, isNavigationLayersEmpty);
      })
    );
  }

  subscribeToMapForSelected(): void {
    this.currentMap = undefined;

    combineLatest([
      this.rootStore.pipe(select(fromMaps.selectSelectedMap), filterUndefined(), delay(1)),
      this.rootStore.pipe(select(fromMaps.selectNavigationLayersBySelectedMapId)),
      this.rootStore.pipe(select(fromSettings.selectMapSettings)),
    ])
      .pipe(
        filter(([selectedMap, layers]) => layers && layers.some(l => l.mapId === selectedMap.id)),
        map(([selectedMap, layers]) => {
          const store = this.storageService.getArray<NavigationLayerStorageModel>(
            LOCALSTORAGE.NAVIGATION_LAYER_CARD
          );

          const storedCards = store.filter(c => c.mapId === selectedMap.id);
          const details = new MapContainerDetails(layers, storedCards);

          return { selectedMap, details };
        }),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        takeUntil(this.ngUnsubscribeLiveData)
      )
      .subscribe(this.onMapSelectionChanged.bind(this));
  }

  private onMapSelectionChanged(map: SelectedMapData) {
    if (
      map.selectedMap.id !== EMPTY_GUID &&
      (!this.currentMap || this.currentMap.selectedMap !== map.selectedMap)
    ) {
      this.currentMap = map;
      this.mapChanged.next(this.currentMap);

      this.startLiveDataServicesByMap(map.selectedMap);
    }
  }

  // #endregion

  // #region Poi Changes
  selectPoiStatusChanged(): Observable<PoiDto> {
    return this.actions$.pipe(
      ofType(fromMaps.layerPoiStatusUpdate),
      mergeMap(p => this.poisStore.select(fromPois.selectPoiById(p.id))),
      filterUndefined(),
      takeUntil(this.ngUnsubscribeLiveData)
    );
  }

  selectPoiChanged(): Observable<PoiDto> {
    return this.actions$.pipe(
      ofType(fromMaps.layerPoiUpdate),
      mergeMap(p => this.poisStore.select(fromPois.selectPoiById(p.id))),
      distinctUntilChanged(),
      filterUndefined(),
      takeUntil(this.ngUnsubscribeLiveData)
    );
  }

  selectPoiCreated(): Observable<PoiDto> {
    return this.actions$.pipe(
      ofType(fromMaps.layerPoiCreated),
      mergeMap(p => this.poisStore.select(fromPois.selectPoiById(p.id))),
      filterUndefined(),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribeLiveData)
    );
  }

  selectPoiDeleted(): Observable<GuidString> {
    return this.actions$.pipe(
      ofType(fromMaps.layerPoiDeleted),
      filterUndefined(),
      map(poi => poi.id),
      distinctUntilChanged(),
      takeUntil(this.ngUnsubscribeLiveData)
    );
  }
  // #endregion

  // #region Zoneset Changes
  private getCurrentZoneSet(): void {
    this.currentZoneSet$ = this.rootStore.pipe(
      select(fromMaps.selectZoneSetBySelectedZoneSetId),
      switchMap(zoneSet => of(zoneSet ?? undefined))
    );
  }

  private subscribeToZoneSetLimitReached(): void {
    const allowedNumberOfZoneSets$ = this.settingsStore.pipe(
      select(fromSettings.selectMapSettings),
      map(settings => settings.allowedNumberOfZoneSets)
    );

    this.isZoneSetLimitReached$ = this.rootStore.pipe(
      select(fromMaps.selectZoneSetBySelectedZoneSetId),
      switchMap(zoneSet => {
        return this.createZoneLimitsObservable(zoneSet, allowedNumberOfZoneSets$);
      }),
      map(res => {
        return this.createZonesetLimitsMap(res);
      })
    );
  }

  private createZonesetLimitsMap(
    res: [ZoneSetDto[], AllowedNumberOfZoneSetsDto] | undefined
  ): boolean {
    if (res) {
      const [zoneSets, settings] = res;
      return (
        zoneSets.filter(zs => zs.status === ZoneSetStatus.Draft).length >=
        settings.allowedNumberOfZoneSets
      );
    }

    return false;
  }

  private createZoneLimitsObservable(
    zoneSet: ZoneSetDto | undefined,
    allowedNumberOfZoneSets$: Observable<AllowedNumberOfZoneSetsDto>
  ): Observable<[ZoneSetDto[], AllowedNumberOfZoneSetsDto] | undefined> {
    if (zoneSet) {
      return combineLatest([
        this.rootStore.pipe(select(fromMaps.selectZoneSetsByMapId(zoneSet.mapId))),
        allowedNumberOfZoneSets$,
      ]);
    }
    return of(undefined);
  }

  // #endregion

  // #region Data Mode
  subscribeWithDataMode(isTrafficAnalysis: boolean): void {
    if (isTrafficAnalysis) {
      this.unsubscribeLiveData();
      void this.subscribeToHistoryData();
    } else {
      this.unsubscribeHistoryData();
      this.subscribeToLiveMapData();
    }
  }

  startLiveDataServicesByMap(map: MapDto): void {
    this.missionTraceSignalRService.leaveMissionList();
    this.missionTraceSignalRService.joinMissionList(map.workAreaId);

    void this.vehicleSignalrSubscriber.leaveVehicleMapAssociationGroup();
    void this.vehicleSignalrSubscriber.leaveVehicleFactsheet();

    void this.vehicleSignalrSubscriber.joinVehicleMapAssociationGroup(map.workAreaId);
    void this.vehicleSignalrSubscriber.joinVehicleFactsheet(map.workAreaId);

    void this.mapVehicleSignalRService.pollMapVehicleUpdate(map.workAreaId, map.id);
  }

  private unsubscribeLiveData(): void {
    this.ngUnsubscribeLiveData.next();
    this.ngUnsubscribeLiveData.complete();
    this.ngUnsubscribeLiveData = new Subject<void>();

    void this.mapVehicleSignalRService.leaveVehicleMapUpdate();
    this.currentMap = undefined;
  }
  // #endregion

  // #region Vehicle Awareness List
  get vehicleAwarenessMessageReceivedNext(): Subject<SignalRNextMessage<VehicleAwarenessDto>> {
    return this.vehicleSignalRService.vehicleAwarenessMessageReceivedNext;
  }

  joinVehicleAwarenessList(): void {
    void this.vehicleSignalrSubscriber.joinVehicleAwarenessList();
  }

  leaveVehicleAwarenessList(): void {
    void this.vehicleSignalrSubscriber.leaveVehicleAwarenessList();
  }
  // #endregion

  // #region Vehicle Data
  private subscribeToLiveMapData(): void {
    this.getLiveVehicleData()
      .pipe(takeUntil(this.ngUnsubscribeLiveData))
      .subscribe(vehicles => {
        this.allVehicles.next(vehicles);
      });

    this.rootStore
      .pipe(select(fromMaps.selectCollisionsByMapId))
      .pipe(takeUntil(this.ngUnsubscribeLiveData))
      .subscribe(allCollisionPoints => {
        this.allCollisionPoints.next(allCollisionPoints);
      });

    this.rootStore
      .pipe(select(fromMaps.selectIntersectionCollisionsByMapId))
      .pipe(takeUntil(this.ngUnsubscribeLiveData))
      .subscribe(allIntersectionCollisionPoints => {
        this.allIntersectionCollisionPoints.next(allIntersectionCollisionPoints);
      });
  }

  private getLiveVehicleData(): Observable<MapVehicle[]> {
    return this.rootStore.pipe(
      select(fromVehicles.selectVehiclesLoaded),
      filter(it => it),
      switchMap(_ => this.rootStore.pipe(select(fromVehicles.selectMapVehicles))),
      map(vehicles => setVehicleDefaultValues(vehicles))
    );
  }

  // #endregion

  // #region History Data
  private async subscribeToHistoryData(): Promise<void> {
    this.rootStore.dispatch(fromSettings.loadTrafficSettings());
    this.rootStore.dispatch(fromSettings.loadZoneSettings());
    this.mapVehicleSignalRService.leaveVehicleMapUpdate();
    await this.signalRNextService.leaveAllGroups();

    this.allVehicles.next([]);
    this.allCollisionPoints.next([]);
    this.allIntersectionCollisionPoints.next([]);

    this.subscribeToConflictDetails();
    this.subscribeToZoneAccessDetails();
  }

  private unsubscribeHistoryData(): void {
    this.ngUnsubscribeHistoryData.next();
    this.ngUnsubscribeHistoryData.complete();
    this.ngUnsubscribeHistoryData = new Subject<void>();
  }

  private subscribeToZoneAccessDetails(): void {
    this.mapCommunicationService.zoneAccessDetails$
      .pipe(takeUntil(this.ngUnsubscribeHistoryData))
      .subscribe(details => {
        if (details.accessDetails) {
          void this.getVehiclesForTrafficAnalysis(details);
        } else {
          this.allVehicles.next([]);
        }
      });
  }

  private subscribeToConflictDetails(): void {
    this.mapCommunicationService.trafficConflictDetails$
      .pipe(takeUntil(this.ngUnsubscribeHistoryData))
      .subscribe(details => {
        if (details.conflictDetails) {
          this.allCollisionPoints.next([mapAdxRecordToCollision(details.conflictDetails)]);
          void this.getVehiclesForTrafficAnalysis(details);
        } else {
          this.allCollisionPoints.next([]);
          this.allVehicles.next([]);
        }
      });
  }

  async getVehiclesForTrafficAnalysis(details: {
    selectedWorkArea: WorkingArea | undefined;
    time: string;
    isTmVehicleMessage: boolean;
  }): Promise<void> {
    if (details.selectedWorkArea) {
      const mapId = await firstValueFrom(this.mapId$);
      let res = details.isTmVehicleMessage
        ? await this.dataPublisherService.GetTrafficManagerVehicleMessages(
            details.selectedWorkArea.organizationName,
            details.selectedWorkArea.name,
            details.time,
            mapId ?? ''
          )
        : await this.dataPublisherService.GetVehicleMessages(
            details.selectedWorkArea.organizationName,
            details.selectedWorkArea.name,
            details.time,
            mapId ?? ''
          );

      res = res.filter(v => v.mapId === mapId);

      this.allVehicles.next(
        res.map(
          (v: AdxVehicleMessageRecord): MapVehicle => ({
            workAreaId: details.selectedWorkArea?.id ?? '',
            availability: VehicleAvailability.Available,
            status: VehicleStatus.Busy,
            batteryLevel: 50,
            batteryLevelStatus: BatteryLevelStatus.Orange,
            isErrorForwardingEnabled: true,
            hasError: false,
            isConnected: true,
            isSwitchedOff: false,
            initializationDateTime: '',
            isRetired: false,
            brakeTestRequired: false,
            softwareDetails: {
              softwareVersion: null,
              iotHubSdkVersion: null,
              atsInterfaceVersion: null,
            },
            softwareVersionChangedDateUtc: '',
            softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
            softwareDownloadPercentage: 0,
            lastStateMessageProcessedUtc: '',
            zoneSetId: '',
            desiredZoneSetId: '',
            internalIdentifier: '',
            ipAddress: '',
            interfaceType: VehicleInterfaceType.Ros,
            fleetId: null,
            vehicleKey: '123456789',
            map: {
              id: v.mapId,
              navigationLayerId: '',
            },
            forkLength: 0,
            trailers: v.trailers ?? null,
            vehicleConflictAreaDimensions: {
              workAreaId: details.selectedWorkArea?.id ?? '',
              vehicleId: v.id,
              lookAheadArea: v.lookaheadArea ?? null,
              deadlockArea: v.deadlockArea ?? null,
              stoppingArea: v.stoppingArea ?? null,
            },
            supportedLoadTypes: [],
            loadType: LoadType.Unknown,
            loadOrientation: 0,
            ...v,
          })
        )
      );
    }
  }

  // #endregion

  // #region Graph Layout
  setGraphLayout(layout: GraphLayout): void {
    this.graphLayout.next(layout);
  }
  // #endregion
}
