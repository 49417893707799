import { AlertNowZoneDto, DirectedZoneDto, Factsheet, ServiceZoneDto, ZoneType } from 'core/dtos';
import {
  CheckboxModel,
  DateString,
  IZoneVertexMapItem,
  MapItem,
  VehicleFilterType,
  Zone,
} from 'core/models';
import { isDate } from 'lodash';

export type RequiredField<T, Field extends keyof T> = Omit<T, Field> & Required<Pick<T, Field>>;

// #region Zones

export function isDirectedZone(zone: Zone): zone is DirectedZoneDto {
  return 'orientation' in zone;
}

export function isAlertNowZone(zone: Zone): zone is AlertNowZoneDto {
  return 'vehicleTypes' in zone;
}

export function isServiceZone(zone: Zone): zone is ServiceZoneDto {
  return 'zoneType' in zone && zone.zoneType === ZoneType.Service;
}

export function isZoneVertex(zone: MapItem | IZoneVertexMapItem): zone is IZoneVertexMapItem {
  return 'index' in zone;
}
// #endregion

// #region Vehicles
export function isFactsheet(factsheet: Factsheet): factsheet is Factsheet {
  return 'vehicleId' in factsheet && 'agvGeometry' in factsheet;
}
// #endregion

export function isVehicleFilterType(filter: CheckboxModel[] | object): filter is VehicleFilterType {
  return 'path' in filter && 'name' in filter && 'pathSegments' in filter;
}

export function isDateString(value: object | number | string): value is DateString {
  if (typeof value === 'string' && value !== '') {
    const date = new Date(value);
    return isDate(date);
  }
  return false;
}
