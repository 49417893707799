<ds-box class="mb-4x" data-cy="card">
  <ds-box-header>
    <app-labeled-input
      label="{{
        'settings.featureToggles.jobAssignmentSection.jobAssignment' | translate | uppercase
      }}"></app-labeled-input>
  </ds-box-header>
  <ds-box-content>
    <app-parky-chargy-jm-toggle
      data-cy="parkingAndChargingToggle"
      translationKey="settings.functions.parkingAndCharging"
      description="settings.featureToggles.jobAssignmentSection.parkingChargingDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleParkyChargy' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.parkingAndCharging"
      [loadedVehiclesToggle]="viewModel.parkingAndChargingLoadedVehicles"
      [isLoaded]="jobManagerSettingsLoaded"
      [requiredPermission]="'ToggleParkyChargy'"
      (saveToggle)="onChangeParkingAndCharging($event)"></app-parky-chargy-jm-toggle>

    <app-toggle
      data-cy="waitOnPoiToggle"
      translationKey="settings.functions.waitOnPoi"
      description="settings.featureToggles.jobAssignmentSection.waitOnPoiDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleWaitOnPoi' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.waitOnPoi"
      [disabled]="!viewModel.parkingAndCharging.isToggledOn"
      [isLoaded]="jobManagerSettingsLoaded"
      [requiredPermission]="'ToggleWaitOnPoi'"
      (saveToggle)="onChangeWaitOnPoi($event)"></app-toggle>

    <app-toggle
      data-cy="assignmentTriggerToggle"
      translationKey="settings.functions.assignmentTrigger"
      description="settings.featureToggles.jobAssignmentSection.assignmentTriggerDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleAssignmentTrigger' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.assignmentTrigger"
      [isLoaded]="jobManagerSettingsLoaded"
      [requiredPermission]="'ToggleAssignmentTrigger'"
      (saveToggle)="onChangeAssignmentTrigger($event)"></app-toggle>
  </ds-box-content>
  <ds-box-content>
    <h5>
      {{ 'settings.featureToggles.jobAssignmentSection.loadingTypeTable.caption' | translate }}
    </h5>
    <app-load-type-table
      [workAreaId]="workAreaId"
      [loadTypeConfigurations]="viewModel.loadTypeSettings.configurations">
    </app-load-type-table>
  </ds-box-content>
</ds-box>
<ds-box class="mb-4x" data-cy="card">
  <ds-box-header>
    <app-labeled-input
      label="{{
        'settings.featureToggles.trafficManagementSection.trafficManagement' | translate | uppercase
      }}"></app-labeled-input>
  </ds-box-header>
  <ds-box-content>
    <div class="tm-changelog mb-4x">
      <a data-cy="tmChangeLog" class="space" (click)="openTrafficManagerChangeLog()">{{
        'settings.featureToggles.trafficManagementSection.trafficManagerChangeLog'
          | translate
          | uppercase
      }}</a>
    </div>
    <app-toggle
      data-cy="trafficManagementToggle"
      translationKey="settings.functions.trafficManagement"
      description="settings.featureToggles.trafficManagementSection.trafficDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleTrafficManagement' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.trafficManagementSettings"
      [isLoaded]="trafficManagerSettingsLoaded"
      [requiredPermission]="'ToggleTrafficManagement'"
      (saveToggle)="onChangeTrafficManagementSettings($event)"></app-toggle>

    <div class="settingsTableDiv">
      <ds-accordion class="configuration-accordion">
        <ds-accordion-item
          data-cy="configurationVehicleTypeParameters"
          contentSpacing="sm"
          variant="slim">
          {{ 'settings.featureToggles.trafficManagementSection.vehicleTypeParameters' | translate }}
          <div class="positionDescription" content>
            <table class="vehicleTypeSettingsTable">
              <thead>
                <tr>
                  <th class="col-8">
                    <div class="columnSeparator">
                      {{ 'settings.featureToggles.trafficManagementSection.property' | translate }}
                    </div>
                  </th>
                  <th class="col-1">
                    {{ 'settings.featureToggles.trafficManagementSection.unitLoad' | translate }}
                  </th>
                  <th class="col-1">
                    {{ 'settings.featureToggles.trafficManagementSection.tuggerTrain' | translate }}
                  </th>
                  <th class="col-1">
                    {{ 'settings.featureToggles.trafficManagementSection.forklift' | translate }}
                  </th>
                  <th class="col-1">
                    {{ 'settings.featureToggles.trafficManagementSection.uagv' | translate }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <span class="align-sub" data-cy="consider">{{
                      'settings.featureToggles.trafficManagementSection.consider' | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerUnitLoad">{{
                      viewModel.trafficManagementSettings.considerUnitLoad
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerTugger">{{
                      viewModel.trafficManagementSettings.considerTuggerTrain
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerForklift">{{
                      viewModel.trafficManagementSettings.considerForklift
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerUagv">{{
                      viewModel.trafficManagementSettings.considerUagv
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="considerIdle">{{
                      'settings.featureToggles.trafficManagementSection.considerIdle' | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerIdleUnitLoad">{{
                      viewModel.trafficManagementSettings.considerIdleUnitLoad
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerIdleTugger">{{
                      viewModel.trafficManagementSettings.considerIdleTuggerTrain
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerIdleForklift">{{
                      viewModel.trafficManagementSettings.considerIdleForklift
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerIdleUagv">{{
                      viewModel.trafficManagementSettings.considerIdleUagv
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="considerInManualMode">{{
                      'settings.featureToggles.trafficManagementSection.considerInManualMode'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerInManualModeUnitLoad">{{
                      viewModel.trafficManagementSettings.considerUnitLoadInManualMode
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerInManualModeTugger">{{
                      viewModel.trafficManagementSettings.considerTuggerTrainInManualMode
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerInManualModeForklift">{{
                      viewModel.trafficManagementSettings.considerForkliftInManualMode
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerInManualModeUagv">{{
                      viewModel.trafficManagementSettings.considerUagvInManualMode
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="align-sub" data-cy="considerInError">{{
                      'settings.featureToggles.trafficManagementSection.considerInError' | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerInErrorUnitLoad">{{
                      viewModel.trafficManagementSettings.considerUnitLoadInError
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerInErrorTugger">{{
                      viewModel.trafficManagementSettings.considerTuggerTrainInError
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerInErrorForklift">{{
                      viewModel.trafficManagementSettings.considerForkliftInError
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="considerInErrorUagv">{{
                      viewModel.trafficManagementSettings.considerUagvInError
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="ignoreCommonVehicleTypeCollisions">{{
                      'settings.featureToggles.trafficManagementSection.ignoreCommonVehicleTypeCollisions'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="ignoreCommonVehicleTypeCollisionsUnitLoad">{{
                      viewModel.trafficManagementSettings.ignoreCommonUnitLoadCollision
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="ignoreCommonVehicleTypeCollisionsTugger">{{
                      viewModel.trafficManagementSettings.ignoreCommonTuggerTrainCollision
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="ignoreCommonVehicleTypeCollisionsForklift">{{
                      viewModel.trafficManagementSettings.ignoreCommonForkliftCollision
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="ignoreCommonVehicleTypeCollisionsUagv">{{
                      viewModel.trafficManagementSettings.ignoreCommonUagvCollision
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaMinimumLengthMeters">{{
                      'settings.featureToggles.trafficManagementSection.lookAheadAreaMinimumLengthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaMinimumLengthMetersUnitLoad">{{
                      viewModel.trafficManagementSettings.unitLoadLookAheadAreaMinimumLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaMinimumLengthMetersTugger">{{
                      viewModel.trafficManagementSettings
                        .tuggerTrainLookAheadAreaMinimumLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaMinimumLengthMetersForklift">{{
                      viewModel.trafficManagementSettings.forkliftLookAheadAreaMinimumLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaMinimumLengthMetersUagv">{{
                      viewModel.trafficManagementSettings.uagvLookAheadAreaMinimumLengthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaSpeedMultiplier">{{
                      'settings.featureToggles.trafficManagementSection.lookAheadAreaSpeedMultiplier'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaSpeedMultiplierUnitLoad">{{
                      viewModel.trafficManagementSettings.unitLoadLookAheadAreaSpeedMultiplier
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaSpeedMultiplierTugger">{{
                      viewModel.trafficManagementSettings.tuggerTrainLookAheadAreaSpeedMultiplier
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaSpeedMultiplierForklift">{{
                      viewModel.trafficManagementSettings.forkliftLookAheadAreaSpeedMultiplier
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaSpeedMultiplierUagv">{{
                      viewModel.trafficManagementSettings.uagvLookAheadAreaSpeedMultiplier
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaWidthMeters">{{
                      'settings.featureToggles.trafficManagementSection.lookAheadAreaWidthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaWidthMetersUnitLoad">{{
                      viewModel.trafficManagementSettings.unitLoadLookAheadAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaWidthMetersTugger">{{
                      viewModel.trafficManagementSettings.tuggerTrainLookAheadAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaWidthMetersForklift">{{
                      viewModel.trafficManagementSettings.forkliftLookAheadAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="lookAheadAreaWidthMetersUagv">{{
                      viewModel.trafficManagementSettings.uagvLookAheadAreaWidthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="loadedExtraWidthMeters">{{
                      'settings.featureToggles.trafficManagementSection.loadedExtraWidthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="loadedExtraWidthMetersUnitLoad">{{
                      viewModel.trafficManagementSettings.unitLoadLoadedExtraWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="loadedExtraWidthMetersTugger">{{
                      viewModel.trafficManagementSettings
                        .tuggerTrainLookAheadAreaLoadedExtraWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="loadedExtraWidthMetersForklift">{{
                      viewModel.trafficManagementSettings.forkliftLoadedExtraWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="loadedExtraWidthMetersUagv">{{
                      viewModel.trafficManagementSettings.uagvLoadedExtraWidthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="rearLengthMeters">{{
                      'settings.featureToggles.trafficManagementSection.rearLengthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="rearLengthMetersUnitLoad">{{
                      viewModel.trafficManagementSettings.unitLoadRearLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="rearLengthMetersTugger">{{
                      viewModel.trafficManagementSettings.tuggerTrainRearLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="rearLengthMetersForklift">{{
                      viewModel.trafficManagementSettings.forkliftRearLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="rearLengthMetersUagv">{{
                      viewModel.trafficManagementSettings.uagvRearLengthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="frontLengthMeters">{{
                      'settings.featureToggles.trafficManagementSection.frontLengthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="frontLengthMetersUnitLoad">{{
                      viewModel.trafficManagementSettings.unitLoadFrontLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="frontLengthMetersTugger">{{
                      viewModel.trafficManagementSettings.tuggerTrainFrontLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="frontLengthMetersForklift">{{
                      viewModel.trafficManagementSettings.forkliftFrontLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="frontLengthMetersUagv">{{
                      viewModel.trafficManagementSettings.uagvFrontLengthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="rearWidthMeters">{{
                      'settings.featureToggles.trafficManagementSection.rearWidthMeters' | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="rearWidthMetersUnitLoad">{{
                      viewModel.trafficManagementSettings.unitLoadRearWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="rearWidthMetersTugger">{{
                      viewModel.trafficManagementSettings.tuggerTrainRearWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="rearWidthMetersForklift">{{
                      viewModel.trafficManagementSettings.forkliftRearWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="rearWidthMetersUagv">{{
                      viewModel.trafficManagementSettings.uagvRearWidthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="stoppingAreaWidthMeters">{{
                      'settings.featureToggles.trafficManagementSection.stoppingAreaWidthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="stoppingAreaWidthMetersUnitLoad">{{
                      viewModel.trafficManagementSettings.unitLoadStoppingAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="stoppingAreaWidthMetersTugger">{{
                      viewModel.trafficManagementSettings.tuggerTrainStoppingAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="stoppingAreaWidthMetersForklift">{{
                      viewModel.trafficManagementSettings.forkliftStoppingAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="stoppingAreaWidthMetersUagv">{{
                      viewModel.trafficManagementSettings.uagvStoppingAreaWidthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaLengthMeters">{{
                      'settings.featureToggles.trafficManagementSection.deadlockAreaLengthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaLengthMetersUnitLoad">{{
                      viewModel.trafficManagementSettings.unitLoadDeadlockAreaLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaLengthMetersTugger">{{
                      viewModel.trafficManagementSettings.tuggerTrainDeadlockAreaLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaLengthMetersForklift">{{
                      viewModel.trafficManagementSettings.forkliftDeadlockAreaLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaLengthMetersUagv">{{
                      viewModel.trafficManagementSettings.uagvDeadlockAreaLengthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaWidthMeters">{{
                      'settings.featureToggles.trafficManagementSection.deadlockAreaWidthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaWidthMetersUnitLoad">{{
                      viewModel.trafficManagementSettings.unitLoadDeadlockAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaWidthMetersTugger">{{
                      viewModel.trafficManagementSettings.tuggerTrainDeadlockAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaWidthMetersForklift">{{
                      viewModel.trafficManagementSettings.forkliftDeadlockAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaWidthMetersUagv">{{
                      viewModel.trafficManagementSettings.uagvDeadlockAreaWidthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaLoadedWidthMeters">{{
                      'settings.featureToggles.trafficManagementSection.deadlockAreaLoadedWidthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaLoadedWidthMetersUnitLoad">{{
                      viewModel.trafficManagementSettings.unitLoadDeadlockAreaLoadedWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaLoadedWidthMetersTugger">{{
                      viewModel.trafficManagementSettings.tuggerTrainDeadlockAreaLoadedWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaLoadedWidthMetersForklift">{{
                      viewModel.trafficManagementSettings.forkliftDeadlockAreaLoadedWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="deadlockAreaLoadedWidthMetersUagv">{{
                      viewModel.trafficManagementSettings.uagvDeadlockAreaLoadedWidthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="footprintBufferMeters">{{
                      'settings.featureToggles.trafficManagementSection.footprintBufferMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="footprintBufferMetersUnitLoad">{{
                      viewModel.trafficManagementSettings.unitLoadFootprintBufferMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="footprintBufferMetersTugger">{{
                      viewModel.trafficManagementSettings.tuggerTrainFootprintBufferMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="footprintBufferMetersForklift">{{
                      viewModel.trafficManagementSettings.forkliftFootprintBufferMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="footprintBufferMetersUagv">{{
                      viewModel.trafficManagementSettings.uagvFootprintBufferMeters
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ds-accordion-item>
      </ds-accordion>

      <ds-accordion class="configuration-accordion">
        <ds-accordion-item
          data-cy="configurationTechnicalParameters"
          contentSpacing="sm"
          variant="slim">
          {{ 'settings.featureToggles.trafficManagementSection.technicalParameters' | translate }}
          <div class="positionDescription" content>
            <table class="trafficManagementSettingsTable">
              <thead>
                <tr>
                  <th>
                    <div class="columnSeparator">
                      {{ 'settings.featureToggles.trafficManagementSection.property' | translate }}
                    </div>
                  </th>
                  <th>
                    {{ 'settings.featureToggles.trafficManagementSection.value' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span class="align-sub" data-cy="minimumStoppingAreaLengthMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.minimumStoppingAreaLengthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="minimumStoppingAreaLengthMetersValue">{{
                      viewModel.trafficManagementSettings.minimumStoppingAreaLengthMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="backwardMovementMinNegativeVelocityMetersPerSecondProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.backwardMovementMinNegativeVelocityMetersPerSecond'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="backwardMovementMinNegativeVelocityMetersPerSecondValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .backwardMovementMinNegativeVelocityMetersPerSecond
                      }}</span
                    >
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="vehicleTurningDetectionAngleRadProperty">{{
                      'settings.featureToggles.trafficManagementSection.vehicleTurningDetectionAngleRad'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="vehicleTurningDetectionAngleRadValue">{{
                      viewModel.trafficManagementSettings.vehicleTurningDetectionAngleRad
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="staleCollisionDistanceMetersProperty">{{
                      'settings.featureToggles.trafficManagementSection.staleCollisionDistanceMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="staleCollisionDistanceMetersValue">{{
                      viewModel.trafficManagementSettings.staleCollisionDistanceMeters
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="convoyMaxConflictAngleRadProperty">{{
                      'settings.featureToggles.trafficManagementSection.convoyMaxConflictAngleRad'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="convoyMaxConflictAngleRadValue">{{
                      viewModel.trafficManagementSettings.convoyMaxConflictAngleRad
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="convoyMaxDistanceToPrioritizedVehicleMetersProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.convoyMaxDistanceToPrioritizedVehicleMeters'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="convoyMaxDistanceToPrioritizedVehicleMetersValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .convoyMaxDistanceToPrioritizedVehicleMeters
                      }}</span
                    >
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="preferredDirectionMaxAngleRadProperty">{{
                      'settings.featureToggles.trafficManagementSection.preferredDirectionMaxAngleRad'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="preferredDirectionMaxAngleRadValue">{{
                      viewModel.trafficManagementSettings.preferredDirectionMaxAngleRad
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="vehicleStateConsiderationTtlSecondsProperty">{{
                      'settings.featureToggles.trafficManagementSection.vehicleStateConsiderationTtlSeconds'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="vehicleStateConsiderationTtlSecondsValue">{{
                      viewModel.trafficManagementSettings.vehicleStateConsiderationTtlSeconds
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="staleDeadlockCollisionDistanceMetersProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.staleDeadlockCollisionDistanceMeters'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="staleDeadlockCollisionDistanceMetersValue">{{
                      viewModel.trafficManagementSettings.staleDeadlockCollisionDistanceMeters
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingHeadsOnMaxOrientationRadProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.antiBlockingHeadsOnMaxOrientationRad'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="antiBlockingHeadsOnMaxOrientationRadValue">{{
                      viewModel.trafficManagementSettings.antiBlockingHeadsOnMaxOrientationRad
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingHeadsOnMaxDistanceMetersProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.antiBlockingHeadsOnMaxDistanceMeters'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="antiBlockingHeadsOnMaxDistanceMetersValue">{{
                      viewModel.trafficManagementSettings.antiBlockingHeadsOnMaxDistanceMeters
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingMovingDetectionMinDistanceMetersProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.antiBlockingMovingDetectionMinDistanceMeters'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingMovingDetectionMinDistanceMetersValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .antiBlockingMovingDetectionMinDistanceMeters
                      }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingMovingDetectionMinOrientationRadProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.antiBlockingMovingDetectionMinOrientationRad'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingMovingDetectionMinOrientationRadValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .antiBlockingMovingDetectionMinOrientationRad
                      }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingMaxStationaryVehicleSecondsProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.antiBlockingMaxStationaryVehicleSeconds'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="antiBlockingMaxStationaryVehicleSecondsValue"
                      >{{
                        viewModel.trafficManagementSettings.antiBlockingMaxStationaryVehicleSeconds
                      }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingHeadsOnEnabledProperty">{{
                      'settings.featureToggles.trafficManagementSection.isAntiBlockingHeadsOnEnabled'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingHeadsOnEnabledValue">{{
                      viewModel.trafficManagementSettings.isAntiBlockingHeadsOnEnabled
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="isAntiBlockingWaitingForEventEnabledProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.isAntiBlockingWaitingForEventEnabled'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingWaitingForEventEnabledValue">{{
                      viewModel.trafficManagementSettings.isAntiBlockingWaitingForEventEnabled
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingInsideZoneEnabledProperty">{{
                      'settings.featureToggles.trafficManagementSection.isAntiBlockingInsideZoneEnabled'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingInsideZoneEnabledValue">{{
                      viewModel.trafficManagementSettings.isAntiBlockingInsideZoneEnabled
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingDisconnectEnabledProperty">{{
                      'settings.featureToggles.trafficManagementSection.isAntiBlockingDisconnectEnabled'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingDisconnectEnabledValue">{{
                      viewModel.trafficManagementSettings.isAntiBlockingDisconnectEnabled
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingErrorStateEnabledProperty">{{
                      'settings.featureToggles.trafficManagementSection.isAntiBlockingErrorStateEnabled'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingErrorStateEnabledValue">{{
                      viewModel.trafficManagementSettings.isAntiBlockingErrorStateEnabled
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingStationaryEnabledProperty">{{
                      'settings.featureToggles.trafficManagementSection.isAntiBlockingStationaryEnabled'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="isAntiBlockingStationaryEnabledValue">{{
                      viewModel.trafficManagementSettings.isAntiBlockingStationaryEnabled
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="isDirectedZoneRuleWithLookaheadAreaEnabledProperty"
                      >{{
                        'settings.featureToggles.trafficManagementSection.isDirectedZoneRuleWithLookaheadAreaEnabled'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="isDirectedZoneRuleWithLookaheadAreaEnabledValue"
                      >{{
                        viewModel.trafficManagementSettings
                          .isDirectedZoneRuleWithLookaheadAreaEnabled
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ds-accordion-item>
      </ds-accordion>
    </div>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-header>
    <app-labeled-input
      label="{{
        'settings.featureToggles.zoneManagementSection.zoneManagement' | translate | uppercase
      }}"></app-labeled-input>
  </ds-box-header>
  <ds-box-content>
    <div data-cy="defaultErrorHandlingOptionValue">
      {{ 'settings.featureToggles.zoneManagementSection.zoneManagementDescription' | translate }}
    </div>
    <div class="settingsTableDiv">
      <ds-accordion class="configuration-accordion">
        <ds-accordion-item
          data-cy="zoneManagementConfigurationVehicleTypeParameters"
          contentSpacing="sm"
          variant="slim">
          {{ 'settings.featureToggles.zoneManagementSection.vehicleTypeParameters' | translate }}
          <div class="positionDescription" content>
            <table class="vehicleTypeSettingsTable">
              <thead>
                <tr>
                  <th class="col-8">
                    <div class="columnSeparator">
                      {{ 'settings.featureToggles.zoneManagementSection.property' | translate }}
                    </div>
                  </th>
                  <th class="col-1">
                    {{ 'settings.featureToggles.zoneManagementSection.unitLoad' | translate }}
                  </th>
                  <th class="col-1">
                    {{ 'settings.featureToggles.zoneManagementSection.tuggerTrain' | translate }}
                  </th>
                  <th class="col-1">
                    {{ 'settings.featureToggles.zoneManagementSection.forklift' | translate }}
                  </th>
                  <th class="col-1">
                    {{ 'settings.featureToggles.zoneManagementSection.uagv' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span class="align-sub" data-cy="zmConsiderManualModeInZoneManagement">{{
                      'settings.featureToggles.zoneManagementSection.considerManualModeInZoneManagement'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="zmConsiderManualModeInZoneManagementUnitLoad"
                      >{{
                        viewModel.zoneManagementSettings.settings
                          .considerManualUnitLoadInZoneManagement
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmConsiderManualModeInZoneManagementTugger">{{
                      viewModel.zoneManagementSettings.settings
                        .considerManualTuggerTrainInZoneManagement
                    }}</span>
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="zmConsiderManualModeInZoneManagementForklift"
                      >{{
                        viewModel.zoneManagementSettings.settings
                          .considerManualForkliftInZoneManagement
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmConsiderManualModeInZoneManagementUagv">{{
                      viewModel.zoneManagementSettings.settings.considerManualUagvInZoneManagement
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="align-sub" data-cy="zmLookAheadAreaWidthMeters">{{
                      'settings.featureToggles.zoneManagementSection.lookAheadAreaWidthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmLookAheadAreaWidthMetersUnitLoad">{{
                      viewModel.zoneManagementSettings.settings.unitLoadLookAheadAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmLookAheadAreaWidthMetersTugger">{{
                      viewModel.zoneManagementSettings.settings.tuggerTrainLookAheadAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmLookAheadAreaWidthMetersForklift">{{
                      viewModel.zoneManagementSettings.settings.forkliftLookAheadAreaWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmLookAheadAreaWidthMetersUagv">{{
                      viewModel.zoneManagementSettings.settings.uagvLookAheadAreaWidthMeters
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="align-sub" data-cy="zmLoadedExtraWidthMetersProperty">{{
                      'settings.featureToggles.zoneManagementSection.loadedExtraWidthMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmLoadedExtraWidthMetersPropertyUnitLoad">{{
                      viewModel.zoneManagementSettings.settings.unitLoadLoadedExtraWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmLoadedExtraWidthMetersPropertyTugger">{{
                      viewModel.zoneManagementSettings.settings
                        .tuggerTrainLookAheadAreaLoadedExtraWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmLoadedExtraWidthMetersPropertyForklift">{{
                      viewModel.zoneManagementSettings.settings.forkliftLoadedExtraWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmLoadedExtraWidthMetersPropertyUagv">{{
                      viewModel.zoneManagementSettings.settings.uagvLoadedExtraWidthMeters
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="align-sub" data-cy="zmRearLengthMetersProperty">{{
                      'settings.featureToggles.zoneManagementSection.rearLengthMeters' | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmRearLengthMetersPropertyUnitLoad">{{
                      viewModel.zoneManagementSettings.settings.unitLoadRearLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmRearLengthMetersPropertyTugger">{{
                      viewModel.zoneManagementSettings.settings.tuggerTrainRearLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmRearLengthMetersPropertyForklift">{{
                      viewModel.zoneManagementSettings.settings.forkliftRearLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmRearLengthMetersPropertyUagv">{{
                      viewModel.zoneManagementSettings.settings.uagvRearLengthMeters
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="align-sub" data-cy="zmRearWidthMetersProperty">{{
                      'settings.featureToggles.zoneManagementSection.rearWidthMeters' | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmRearWidthMetersPropertyUnitLoad">{{
                      viewModel.zoneManagementSettings.settings.unitLoadRearWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmRearWidthMetersPropertyTugger">{{
                      viewModel.zoneManagementSettings.settings.tuggerTrainRearWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmRearWidthMetersPropertyForklift">{{
                      viewModel.zoneManagementSettings.settings.forkliftRearWidthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmRearWidthMetersPropertyUagv">{{
                      viewModel.zoneManagementSettings.settings.uagvRearWidthMeters
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="align-sub" data-cy="zmFrontLengthMetersProperty">{{
                      'settings.featureToggles.zoneManagementSection.frontLengthMeters' | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmFrontLengthMetersPropertyUnitLoad">{{
                      viewModel.zoneManagementSettings.settings.unitLoadFrontLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmFrontLengthMetersPropertyTugger">{{
                      viewModel.zoneManagementSettings.settings.tuggerTrainFrontLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmFrontLengthMetersPropertyForklift">{{
                      viewModel.zoneManagementSettings.settings.forkliftFrontLengthMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmFrontLengthMetersPropertyUagv">{{
                      viewModel.zoneManagementSettings.settings.uagvFrontLengthMeters
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="align-sub" data-cy="zmFootprintBufferMetersProperty">{{
                      'settings.featureToggles.zoneManagementSection.footprintBufferMeters'
                        | translate
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmFootprintBufferMetersPropertyUnitLoad">{{
                      viewModel.zoneManagementSettings.settings.unitLoadFootprintBufferMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmFootprintBufferMetersPropertyTugger">{{
                      viewModel.zoneManagementSettings.settings.tuggerTrainFootprintBufferMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmFootprintBufferMetersPropertyForklift">{{
                      viewModel.zoneManagementSettings.settings.forkliftFootprintBufferMeters
                    }}</span>
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmFootprintBufferMetersPropertyUagv">{{
                      viewModel.zoneManagementSettings.settings.uagvFootprintBufferMeters
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ds-accordion-item>
      </ds-accordion>
      <ds-accordion class="configuration-accordion">
        <ds-accordion-item
          data-cy="zoneManagementConfigurationTechnicalParameters"
          contentSpacing="sm"
          variant="slim">
          {{ 'settings.featureToggles.zoneManagementSection.technicalParameters' | translate }}
          <div class="positionDescription" content>
            <table class="trafficManagementSettingsTable">
              <thead>
                <tr>
                  <th>
                    <div class="columnSeparator">
                      {{ 'settings.featureToggles.zoneManagementSection.property' | translate }}
                    </div>
                  </th>
                  <th>
                    {{ 'settings.featureToggles.zoneManagementSection.value' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="zmMaxDistanceToIntersectionZoneMetersProperty"
                      >{{
                        'settings.featureToggles.zoneManagementSection.maximumDistanceToIntersectionZoneMeters'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span class="align-sub" data-cy="zmMaxDistanceToIntersectionZoneMetersValue">{{
                      viewModel.zoneManagementSettings.settings
                        .maximumDistanceToIntersectionZoneMeters
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="zmIsAntiBlockingStaleDistanceToZoneRuleEnabledProperty"
                      >{{
                        'settings.featureToggles.zoneManagementSection.isAntiBlockingStaleDistanceToZoneRuleEnabled'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="zmIsAntiBlockingStaleDistanceToZoneRuleEnabledValue"
                      >{{
                        viewModel.zoneManagementSettings.settings
                          .isAntiBlockingStaleDistanceToZoneRuleEnabled
                      }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="zmAntiBlockingStaleDistanceToZoneRuleSecondsProperty"
                      >{{
                        'settings.featureToggles.zoneManagementSection.antiBlockingStaleDistanceToZoneRuleSeconds'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="zmAntiBlockingStaleDistanceToZoneRuleSecondsValue"
                      >{{
                        viewModel.zoneManagementSettings.settings
                          .antiBlockingStaleDistanceToZoneRuleSeconds
                      }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="zmMaximumCircularPathSplitDistanceMetersProperty"
                      >{{
                        'settings.featureToggles.zoneManagementSection.maximumCircularPathSplitDistanceMeters'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="zmMaximumCircularPathSplitDistanceMetersValue"
                      >{{
                        viewModel.zoneManagementSettings.settings
                          .maximumCircularPathSplitDistanceMeters
                      }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="zmCoordinationZoneDeadlockResolutionStationarySecondsProperty"
                      >{{
                        'settings.featureToggles.zoneManagementSection.coordinationZoneDeadlockResolutionStationarySeconds'
                          | translate
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="align-sub"
                      data-cy="zmCoordinationZoneDeadlockResolutionStationarySecondsValue"
                      >{{
                        viewModel.zoneManagementSettings.settings
                          .coordinationZoneDeadlockResolutionStationarySeconds
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ds-accordion-item>
      </ds-accordion>
    </div>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <app-toggle
      data-cy="deviceSettingToggle"
      translationKey="settings.functions.deviceSettings"
      description="settings.featureToggles.amaSection.deviceSettingsDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleAMAFunctionality' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.deviceSettings"
      [isLoaded]="jobManagerSettingsLoaded"
      [requiredPermission]="'ToggleAMAFunctionality'"
      (saveToggle)="onChangeAmaSettings($event)"></app-toggle>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <app-toggle
      data-cy="enableWaitingQueueToggle"
      translationKey="settings.functions.waitingQueue"
      description="settings.featureToggles.amaSection.enableWaitingQueueDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleWaitingQueueMode' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.waitingQueueSettings"
      [isLoaded]="jobManagerSettingsLoaded"
      [requiredPermission]="'ToggleWaitingQueueMode'"
      (saveToggle)="onChangeWaitingQueueSettings($event)"></app-toggle>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <app-toggle
      data-cy="enableGraphManagerFeature"
      translationKey="settings.functions.graphManager"
      description="settings.functions.graphManager.Description"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleWaitingQueueMode' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.graphManagerSettings"
      [isLoaded]="graphManagerSettingsLoaded"
      [requiredPermission]="'ToggleWaitingQueueMode'"
      (saveToggle)="onChangeGraphManagerSettings($event)"></app-toggle>

    <app-toggle
      data-cy="missionAssignmentOptimizationToggle"
      translationKey="settings.functions.graphManager.missionAssignment"
      description="settings.functions.graphManager.missionAssignment.missionAssignmentOptimizationDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleMissionAssignment' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.graphManagerMissionAssignmentOptimization"
      [isLoaded]="graphManagerSettingsLoaded"
      [requiredPermission]="'ToggleMissionAssignment'"
      (saveToggle)="onChangeMissionAssignmentOptimization($event)"></app-toggle>

    <app-parky-chargy-toggle
      [isLoaded]="graphManagerParkingAndChargingSettingsLoaded"
      [parkingAndChargingSettings]="viewModel.graphManagerParkingAndChargingSettings"
      (updateParkyChargyVehicleToggle)="
        onChangeGraphManagerParkingAndChargingSettings($event)
      "></app-parky-chargy-toggle>
    <ds-accordion class="configuration-accordion">
      <ds-accordion-item
        data-cy="parkingAndChargingSettingsAccordion"
        contentSpacing="sm"
        variant="slim">
        {{ 'settings.functions.graphManager.parkingCharging.settings' | translate }}
        <div class="parky-chargy-settings-container" content>
          <app-parky-chargy-configuration
            [parkingAndChargingSettings]="viewModel.graphManagerParkingAndChargingSettings"
            [isLoaded]="graphManagerParkingAndChargingSettingsLoaded">
          </app-parky-chargy-configuration>
        </div>
      </ds-accordion-item>
    </ds-accordion>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <app-toggle
      data-cy="enableVehicleSmoothing"
      translationKey="settings.functions.vehicleSmoothing"
      description="settings.functions.vehicleSmoothing.Description"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleVehicleSmoothing' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="viewModel.vehicleSmoothingSettings"
      [isLoaded]="mapManagerSettingsLoaded"
      [requiredPermission]="'ToggleVehicleSmoothing'"
      (saveToggle)="onChangeVehicleSmoothingSettings($event)"></app-toggle>
  </ds-box-content>
</ds-box>

<ds-box class="mb-4x" data-cy="card">
  <ds-box-content>
    <div class="title">
      <app-labeled-input
        label="{{
          'settings.functions.missionErrorHandlingDefaults.title' | translate
        }}"></app-labeled-input>
      <div
        class="ms-2x"
        *ngIf="jobManagerSettingsLoaded; else error"
        [class.icon-disabled]="!hasPermission">
        <span data-cy="EditDefaultErrorHandling" (click)="onEditMissionErrorHandlingDefaults()"
          ><i
            class="pi pi-pencil"
            ds-tooltip="{{
              ('settings.roles.permissions.Tooltip_Pretext' | translate) +
                ('settings.roles.permissions.ToggleMissionAssignment' | translate) +
                ('settings.roles.permissions.Tooltip_Posttext' | translate)
            }}"
            [dsTooltipConfig]="{ disabled: hasPermission }"></i
        ></span>
      </div>
      <ng-template #error>
        <div class="error-background">
          <label data-cy="errorMessage" class="label-value error-text"
            ><ds-icon class="ms-2x icon" icon="error" tone="critical"></ds-icon>
            {{ 'settings.functions.unknownValue' | translate }}
          </label>
        </div>
      </ng-template>

      <div class="last-changed-date">
        <span> <i class="pi pi-clock"></i>{{ 'settings.functions.lastChange' | translate }}</span>
        <span data-cy="date">
          {{
            viewModel.missionErrorHandlingDefaults.dateUpdated
              ? (viewModel.missionErrorHandlingDefaults.dateUpdated.toString() | formatDateNow)
              : ('settings.functions.neverUpdated' | translate)
          }}
        </span>
      </div>
    </div>
    {{ 'settings.functions.missionErrorHandlingDefaults.description' | translate }}

    <div class="ms-1x mt-2x">
      <div class="title">
        <app-labeled-input
          label="{{
            'settings.featureToggles.jobAssignmentSection.missionErrorHandlingDefaultMainOption'
              | translate
          }}"></app-labeled-input>
      </div>
      <div data-cy="defaultErrorHandlingOptionValue">
        {{
          viewModel.missionErrorHandlingDefaults.defaultErrorHandlingOption || 0
            | enumTranslation : 'errorHandlingOptions'
            | notApplicable
        }}
      </div>

      <div class="title mt-4x">
        <app-labeled-input
          label="{{
            'settings.featureToggles.jobAssignmentSection.defaultValuesForCancelAbort' | translate
          }}"></app-labeled-input>
      </div>
      <div
        *ngIf="viewModel.missionErrorHandlingDefaults.releaseInterlock"
        data-cy="releaseInterlockValue">
        {{ 'shared.missionErrorHandlingModal.confirmInterlock' | translate }}
      </div>
      <div
        *ngIf="viewModel.missionErrorHandlingDefaults.confirmAbortToSap"
        data-cy="confirmAbortToSapValue">
        {{
          viewModel.missionErrorHandlingDefaults.confirmAbortToSapAs || 0
            | enumTranslation : 'missionTraceSapConfirmationOptions'
            | notApplicable
        }}
      </div>
    </div>
  </ds-box-content>
</ds-box>

<app-mission-error-handling-settings-modal
  [isModalOpen]="isModalOpen"
  [missionErrorHandlingDefaultsData]="viewModel.missionErrorHandlingDefaults"
  (confirm)="onConfirm($event)"
  (dismissModal)="dismissModal()">
</app-mission-error-handling-settings-modal>
